.services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.service{
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
    cursor: pointer;
    height: 100%;

    &:hover{
        background: transparent;
        border-color: var(--color-primary-variant);
        cursor: default;

        ul.service__list li {
            color: #1070bc;
        }
    }
}

.service__head{
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);

    h3{
        color: var(--color-bg);
        font-size: 1rem;
        text-align: center;
    }
}


.service__list{
    padding: 2rem;

    li {
        padding: 0.5rem 0;
    }
    svg{
        fill: var(--color-primary);
    } 
}

div#services .about--content{
    ul{
        margin-left: 0;
        li {
            list-style: none;
            svg {
                fill: green;
                padding-top: 4px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .services__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service{
        height: auto;
    }
    .service:last-child{
        grid-column: span 2;
        width: 50%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px){
    .services__container{
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    .service:last-child{
        grid-column:auto;
        width: 100%;
    }
}