section#experience {
    padding: 5rem 0;
    background-image: linear-gradient( 89.7deg,  rgba(0,0,0,1) -10.7%, rgba(53,92,125,1) 88.8% );
}

.container.exp_container{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 90%;

    & > div{
        padding: 2.4rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);
    }
    .exp__content{
        h2{
            text-align: center;
            margin: 0 0 2rem;
            color: var(--color-primary);
        }
    } 
}


.expDetails-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 1rem;
    grid-column-gap: 1rem;

    article.exp_details{
        background: transparent;
        border-radius: 20px;
        opacity: 1;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(20px);
        background: rgba(255, 255, 255, .1);
        width: 100% !important;
        max-width: 100%;
        padding: 2rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-direction: column;
        

        &>div {
            padding: 2.4rem;
            border-radius: 2rem;
            transition: var(--transition);
            background: transparent;
            padding: 0;
        }

        &>div>h4 {
            line-height: 1;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            font-size: 14px;
        }

        svg{
            color: var(--color-primary);
        }
    }
}



.work-experience .container {
    padding: 2rem 2rem 3rem;
    margin-bottom: 3rem;
    text-align: center;
    background: #0d1117;
    border-radius: 2rem;
    width: 90%;

    h2, p{
        font-family: 'Montserrat';
    }
    h2 {
        font-size: 18px;
    }
    p{
        position: relative;

        &::after {
            content: "";
            display: block;
            font-size: 61px;
            color: #61DBFB;
            line-height: 1;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            border-bottom: 1px solid #61DBFB;
            width: 9%;
            margin-top: 1rem;
            border-bottom: 2px solid transparent;
            border-image: linear-gradient(0.25turn, rgba(255,249,34), rgba(255,0,128), rgba(56,2,155,0));
            border-image-slice: 1;
        }
    }
    
}

.work-experience .container svg {
    font-size: 28px;
    color: #feb602;
}



.exp_details svg {
    font-size: 30px;
    color: #fff;
}

article.exp_details{
    &.html > svg{
        color: #e34c26;
    }
    &.css svg {
        color: #264de4;
    }
    &.scss svg {
        color: #CD6799;
    }
    &.js svg {
        color: #f0db4f;
    }
    &.bootstrap svg {
        color: #563d7c;
    }
    &.reactjs svg {
        color: #61DBFB;
    }
    &.laravel svg {
        color: #f55247;
    }
    &.bc svg {
        color: #000;
    }
    &.wordpress svg {
        color: #21759b;
    }
    &.shopify svg {
        color: #96bf48;
    }
    &.graphql svg {
        color: #e10098;
    }
    &.postman svg {
        color: #ef5b25;
    }
    &.jquery svg {
        color: #264de4;
    }
    &.vs svg {
        color: #007ACC;
    } 
    &.git svg {
        color: #f34f29;
    }
}

section#experience .blurb-title {
    color: #fff;
}



@media screen and (max-width: 1024px) {
    .container.exp_container{
        grid-template-columns: 1fr;
    }
    .container.exp_container>div{
        padding: 2rem;
        margin: 0 auto;
    }
    .exp__content{

    }
    .expDetails-container {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media screen and (max-width: 800px){
    .expDetails-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 600px){
    .container.exp_container{
        gap: 1rem;
    }
    .container.exp_container>div{
        width: 100%;
        padding: 2rem 1rem;
    }
    .expDetails-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 1025px) {
    .expDetails-container article.exp_details:nth-last-child(-n+1) {
        grid-column: 4 / span 1;
        justify-self: center;
    }
    
    .expDetails-container article.exp_details:nth-last-child(2) {
        grid-column: 3 / span 1;
        justify-self: center;
    }
    .expDetails-container article.exp_details:nth-last-child(3) {
        grid-column: 2 / span 1;
        justify-self: center;
    }
    
}


