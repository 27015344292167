section#portfolio {
    background: var(--light-grad-pink-blue, radial-gradient(ellipse at 40% 0%, #bf398910 0, transparent 75%), radial-gradient(ellipse at 60% 0%, #096bde10 0, transparent 75%));
    background-color: #0d1117;
    margin-top: 4rem;
    padding: 4rem 0;

    h2.blurb-title{
        color: #fff;
        font-size: 40px;
    }
}

.container.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    width: 90%;

    .portfolio-item{
        background: var(--color-bg-variant);
        padding: 1.5rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);
        background-color: rgba(204,220,255,.12);
        &:hover{
            border-color: rgba(204,220,255,.12);
            background: transparent;
            background: #0d1117;
            cursor: pointer;

            h3, h5{
                color: #fff;
            }
            .portfolio__item-body{
                background-color: #161b22;
            }
            img {
                transform: scale(1.15);
            }
        }

        img {
            transition-property: all;
            transition-timing-function: cubic-bezier(.4,0,.2,1);
            transition-duration: .3s;
        }

        h5 a{
            color: #fff;
        }
        h3 {
            margin: 0rem 0 1rem;
        }
    }

}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
    margin-bottom: 1rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1.5rem;
}
.portfolio__item-cta a, .portfolio__item-cta a:hover{
    background: #1070bc;
    color: #fff;
}

.portfolio__item-body {
    padding: 1rem;
    border-radius: 20px;
    background-color: rgba(255,255,255,.05);
    h5 {
        font-size: 16px;
    }
    .portfolio__item-cta a {
        border-radius: 12rem;
        padding: 8px 2rem;
        vertical-align: baseline;
        display: grid;
        align-content: center;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        grid-column-gap: 8px;
    }
}

@media screen and (max-width: 1024px) {
    .container.portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
    }
}

@media screen and (max-width: 600px){
    .container.portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}


