@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root{
    --color-bg: #132448;
    --color-bg-variant: #2c2c6c;
    --color-primary: #1070bc;
    --color-primary-variant: #1070bc ;
    --color-white : #fff;
    --color-1ight: #0e1111;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --font-family-p: "Raleway";

    --transitions: all 400ms ease;
}
html{
    scroll-behavior : smooth
}
html, body {
    overflow-x: hidden;
}
body{
    font-family: var(--font-family-p);
    background: var(--color-bg) ;
    color: var( --color-white) ;
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.png) ; */
    background: #ffffff;
    overflow-x: hidden;
}
body.is-open {
    overflow: hidden;
}
/* General Styles */
.container {
    width: var(--container-width-lg);
    margin: auto;
}
    h1,
    h2,
    h3,
    h4,
    h5{
        font-weight: 500;
        font-family: 'Montserrat';
    }

    h1{font-size: 2.5rem;}
    section{
        margin-top: 8rem;
    }

    section>h2, 
    section>h5{
        text-align: center;
        color: var(--color-1ight);
    }

    section > h2 {
        color: var(--color-primary) ;
        margin-bottom : 3rem;
    }

    .text-light {
        color: var(--color-light);
    }

    a{
        color: var(--color-primary);
        transition: var(--transitions);
    }

    a:hover{
        color: var(--color-white);
    }

    .btn {
        width: max-content;
        display: inline-block;
        color: var(--color-primary);
        padding: 0.75rem 1.2rem;
        border-radius : 0.4rem;
        Cursor: pointer;
        border: 1px solid var(--color-primary);
        transition: var(--transitions);

    }

    .btn:hover{
        background-color: var(--color-white);
        color: var(--color-bg);
        border-color: transparent;
    }

    .btn-primary{
        background: var(--color-primary);
        color: var(--color-bg);
    }

    img{
        display: block;
        width: 100%;
    }


/*-------------------------- MEDIA QUERIES (MEDIUM Devices)----------------------- */

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }
    section{
        margin-top: 6rem;
    }
}

/*-------------------------- MEDIA QUERIES (SMALL Devices)----------------------- */
@media screen and (max-width: 900px) {
    h2.blurb-title {
        font-size: 25px;
    }
    section#portfolio  h2.blurb-title {
        font-size: 25px;
    }
}
@media screen and (max-width: 600px){
    .container{
        width: var(--container-width-md);
    }

    section>h2{
        margin-bottom: 2rem;
    }
}

/* PARTICLE JS */


div#particles-js canvas{
    position: fixed;
    top: 0;
    z-index: -1;
}
.d-none{
    display: none;
}

.text-dark{
    color: #0e1111;
}